import { createContext, useContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

type State = {
  user: CognitoUser | null | string;
};

export const AuthContext = createContext<State | undefined>(undefined);

export default function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
