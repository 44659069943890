interface LetterPreviewProps {
  src: string;
}

export default function LetterPreview({ src }: LetterPreviewProps) {
  return (
    <>
      <h3 className="mx-2 p-2 font-bold text-pipgreen-dark text-center">letter preview</h3>
      <object data={src} title="pdf preview" className="w-full h-full" aria-label="letter-preview" />
    </>
  );
}
