import { MenuItemProps } from '../../types/global';

export default function MenuItems({ item, className, isExpanded }: MenuItemProps) {
  return (
    <li className={className}>
      <a href={item.path} className="flex items-center ml-2 cursor-pointer" aria-label={`Navigate to ${item.name}`}>
        {isExpanded ? <span aria-hidden>{item.icon}</span> : <span title={item.name}>{item.icon}</span>}
        <span className={`${isExpanded ? 'inline ml-2 flex-grow' : 'hidden'}`}>{item.name}</span>
      </a>
    </li>
  );
}
