/* eslint-disable react/jsx-props-no-spreading */
import { Form, redirect } from 'react-router-dom';
import { useState } from 'react';
import AdvancedInput from '../components/advancedInput';
import SplitLayout from '../components/splitLayout';
import { createCampaign } from '../utils/campaign';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import InfoBubble from '../components/infoBubble';
import { StepState } from '../types/global';

export async function action({ request }:{ request:any }) {
  const formData = await request.formData();
  const campaignInformation = Object.fromEntries(formData);
  const newCampaign = await createCampaign(campaignInformation);
  return redirect(`/campaigns/new/${newCampaign.id}`);
}

export default function NewCampaign() {
  const [selected, setSelected] = useState<StepState>({
    campaignType: '',
    snapShot: '',
    campaignName: '',
  });

  const handleChange = (dropdownId:string, value:string) => {
    if (value === 'default') return;

    setSelected((prevState) => ({
      ...prevState,
      [dropdownId]: value,
    }));
  };

  const dropdownData = {
    id: 'campaignType',
    options: ['Pension Credit', 'Option 2', 'Option 3'],
    onChange: (event:any) => handleChange(dropdownData.id, event.target.value),
  };
  const snapShot = {
    id: 'snapShot',
    options: ['01/24', '02/24', '03/24'],
    onChange: (event:any) => handleChange(snapShot.id, event.target.value),
  };

  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };
  const steps = [{
    icon: customIcons.folderOpenIcon,
    step: 'Choose your campaign',
    info: 'Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.',
  }, {
    icon: customIcons.fileCheck2Icon,
    step: 'Create Campaign',
    info: 'This will take you to the next page where you\u2019ll be able to preview the letter',
  }, {
    icon: customIcons.calendarIcon,
    step: 'Choose Snapshot',
    info: 'Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.',
  }, {
    icon: customIcons.previewIcon,
    step: 'Preview Letter & Send',
    info: 'Request to Policy in Pracitce',
  }];

  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Create new campaign</h1>
      <SplitLayout leftWeight="" rightWeight="">
        <Steps steps={steps} titleObject={titleObject} input={selected} />
        <div className="w-full mx-2 p-2 flex flex-col">
          <h2 className="text-2xl font-medium text-left">Choose Your Campaign</h2>
          <InfoBubble />
          <Form id="create-campaign" className="w-full" method="post">
            <AdvancedInput type="text" label="Campaign Name" name="campaignName" id="campaignName" onChange={(e: any) => handleChange('campaignName', e.target.value)} required />
            <AdvancedInput type="dropdown" label="Campaign Type" {...dropdownData} name="campaignType" id="campaignType" value={selected.campaignType} onChange={dropdownData.onChange} required />
            <AdvancedInput type="dropdown" label="Snap Shot Data " {...snapShot} name="snapShot" id="snapShot" value={selected.snapShot} onChange={snapShot.onChange} required />
          </Form>
        </div>
      </SplitLayout>
      <div className="w-full mt-auto mb-6 flex justify-center md:justify-end">
        <button type="submit" form="create-campaign" className="w-2/3 md:w-1/3 p-2 bg-pipgreen-dark rounded-lg text-pipgrey-light hover:bg-opacity-90">Start Campaign</button>
      </div>
    </div>
  );
}
