/* eslint-disable react-refresh/only-export-components */
import { Form, redirect, useLoaderData } from 'react-router-dom';
import { useState } from 'react';
import pensionCreditLetter from '../assets/pension_credit_letter_template.pdf';
import SplitLayout from '../components/splitLayout';
import LetterPreview from '../components/campaigns/letterPreview';
import List from '../components/campaigns/list';
import { getCampaign } from '../utils/campaign';
import { CampaignData } from '../types/global';
import Modal from '../components/modal';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';

export const loader = ({ params }:{ params:any }) => {
  // use the params to fetch campaign data based on the id.
  const { campaignId } = params;
  const campaignData = getCampaign(campaignId);
  return campaignData;
};

export const action = async ({ params }:{ request: any, params: any }) => {
  const { campaignId } = params;
  // use the campaignId to update the campaign data in the database
  console.log('campaignData:', campaignId);
  const campaignInformation = await getCampaign(campaignId);
  if (!campaignInformation) return redirect('/campaigns');
  const { status } = campaignInformation;
  console.log('status:', status);
  return redirect(`/campaigns/${campaignId}`);
};

export default function CampaignSummary() {
  const data = useLoaderData() as CampaignData;
  const { campaignName, campaignType, snapShot } = data.campaignInformation;
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    console.log('modal:', isModalOpen);
    setModalOpen((previousState) => !previousState);
  };

  const campaign = {
    'Campaign Name': campaignName,
    'Campaign Type': campaignType,
    snapshot: snapShot,
  };
  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const steps = [{
    icon: customIcons.folderOpenIcon,
    step: 'Choose your campaign',
    info: 'Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.',
  }, {
    icon: customIcons.fileCheck2Icon,
    step: 'Create Campaign',
    info: 'This will take you to the next page where you\u2019ll be able to preview the letter',
  }, {
    icon: customIcons.calendarIcon,
    step: 'Choose Snapshot',
    info: 'Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.',
  }, {
    icon: customIcons.previewIcon,
    step: 'Preview Letter & Send',
    info: 'Request to Policy in Pracitce',
  }];

  return (
    <div className="h-screen flex flex-col w-full p-4">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Preview Campaign</h1>
      <SplitLayout leftWeight="" rightWeight="">
        {campaignName && campaignType && snapShot && (
        <Steps titleObject={titleObject} steps={steps} input={{ campaignName, campaignType, snapShot }} />
        )}

        <div className="flex flex-grow flex-col">
          <List campaign={campaign} />
          <LetterPreview src={pensionCreditLetter} />
        </div>

      </SplitLayout>
      { isModalOpen && (
      <Modal isOpen={isModalOpen} hasCloseBtn onClose={handleModalOpen}>
        <span className="text-6xl text-pipgreen">{customIcons.checkCircleIcon}</span>
        <h2 className="text-xl font-bold text-pipgreen-dark">Success! </h2>
        <p className="font-thin whitespace-wrap">This has now been sent to Polcy in Practice for review. You can check its status on the status page.</p>
        <div className="w-full flex justify-between p-2">
          <button type="button" id="cancelModalButton" className="p-1 w-1/2 m-2 shadow-inner bg-white rounded-md text-pip-background border" onClick={handleModalOpen}>Cancel</button>
          <Form method="post" className="w-1/2" replace>
            <button type="submit" className="w-full p-1 m-2 bg-pipgreen rounded-md text-white">Go to Latest Campaign</button>
          </Form>
        </div>
      </Modal>
      )}
      <div className="w-full m-2 flex justify-center">
        <button type="button" className="w-1/2 p-2 bg-pipgreen rounded-md text-white" onClick={handleModalOpen}>Send to Policy in Practice</button>
      </div>
    </div>
  );
}
