import { SetStateAction, useState } from 'react';
import {
  Form, LoaderFunctionArgs, redirect, useActionData, useNavigation,
} from 'react-router-dom';
import { LoaderData } from '../../types/global';

// eslint-disable-next-line react-refresh/only-export-components
export async function loginAction({ request }: LoaderFunctionArgs) {
  const formData = await request.formData();
  const orgName = formData.get('org') as string | null;
  // Validate our form inputs and return validation errors via useActionData()
  if (!orgName) {
    return {
      error: 'You must choose an org to log in',
    };
  }
  // Sign in and redirect if successful.
  try {
    // as we will most likely be getting org names from the auth provider or from a register where there will be
    // spaces, commas, and ampersands, we are removing commas, ampersands and replaced them with hypens and lowercasing it for the url path
    const orgPath = orgName.replace(/[\s,&]+/g, '-').toLowerCase();
    // redirects to a path that will be picked up on the backend and redirect to appropriate auth provider/cognito url
    return redirect(`/backend/auth/${orgPath}`);
  } catch (error) {
    return {
      error: 'Invalid login attempt',
    };
  }
}

export default function LoginForm({ orgNames }: LoaderData) {
  const [selectedOrg, setSelectedOrg] = useState('');
  const navigation = useNavigation();
  const actionData = useActionData() as { error: string } | undefined;

  const isLoggingIn = navigation.formData?.get('org') != null;

  const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedOrg(e.target.value);
  };

  return (
    <div className="w-full items-center justify-center flex flex-col m-1 rounded-sm">
      <Form method="post" replace>
        <label htmlFor="org">
          <select name="org" className="text-pipgrey text-center m-1 p-1 w-full rounded-md border border-pipgrey-light" value={selectedOrg} onChange={handleChange}>
            <option value="" disabled>Select your organisation</option>
            {orgNames !== undefined && Array.isArray(orgNames) ? orgNames.map((org:string) => <option key={org} value={org}>{org}</option>) : 'select'}
          </select>
        </label>
        <button type="submit" disabled={isLoggingIn} className="bg-white m-1 p-1 w-full rounded-md border border-pipgrey-light hover:shadow-md">
          {isLoggingIn ? 'Logging in...' : 'Login'}
        </button>
        {actionData && actionData.error ? (
          <p style={{ color: 'red' }}>{actionData.error}</p>
        ) : null}
      </Form>
    </div>
  );
}
