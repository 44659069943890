/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  let errorMessage: string;

  // make error messages more vague eg. "failed to load data" so as to not reveal too much to the user

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    errorMessage = error.data.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorMessage = 'Unknown error';
  }

  const errorString = "If you can see this, you've had an error trying to reach the Low-Income-Family-Tracker. If you're a Policy in Practice customer, please contact your Policy in Practice support representative for further assistance. If you're a Policy in Practice staff member, please DM a member of the Dev team with the URL you were trying to reach, and we'll take a look.";

  return (
    <div id="error-page">
      <h1>Not Found</h1>
      <p>{errorString}</p>
    </div>
  );
}
