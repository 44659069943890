import AdvancedInput from '../components/advancedInput';
import InformationText from '../components/InformationText';
import { UserProps, InformationProps } from '../types/global';
import Table from '../components/table';
import customIcons from '../utils/icons';
import SplitLayout from '../components/splitLayout';

export default function Settings() {
  const data: InformationProps = {
    title: 'Personal Information',
    subtitle: 'Update your personal information',
  };
  const tableObject = [{
    user: 'first lastname',
    role: 'admin',
  }, {
    user: '2first lastname',
    role: '2admin',
  }, {
    user: '3first lastname',
    role: '3admin',
    email: 'email.com',
  }];

  // the key of this object becomes the header of the additional column
  const ActionColumn = {
    Action: [{ title: 'edit', icon: customIcons.editIcon },
      { title: 'archive', icon: customIcons.archiveIcon }],
  };

  const user:UserProps[] = [{
    'first name': 'John',
    'last name': 'Doe',
    role: 'Admin',
    SSO: 'john.doe@pip.com',
  }];
  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <SplitLayout leftWeight="md:w-4/12" rightWeight="flex-wrap md:w-8/12">
        <InformationText title={data.title} subtitle={data.subtitle} />
        {user.map((item, index) => (
          Object.keys(item).map((key) => (
            <AdvancedInput key={`${key}-${item[index]}`} type="text" id={key} label={key} value={item[key]} disabled />
          ))
        ))}
      </SplitLayout>
      <SplitLayout leftWeight="md:w-4/12" rightWeight="md:w-8/12">
        <InformationText title="Your Team" subtitle="Edit user roles or archive users" />
        <Table tableObject={tableObject} ActionColumn={ActionColumn} title="You Team" count="user" />
      </SplitLayout>
    </div>

  );
}
