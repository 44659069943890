import {
  LuSend as Send,
  LuHome as Home,
  LuMap as Map,
  LuLineChart,
  LuScrollText,
  LuCog,
  LuLifeBuoy,
  LuLogOut,
  LuPencil,
  LuArchive,
  LuX,
  LuFolderOpen,
  LuCalendarDays,
  LuFileCheck2,
  LuEye,
  LuInfo,
  LuCheckCircle2,
} from 'react-icons/lu';

const customIcons = {
  homeIcon: <Home />,
  sendIcon: <Send />,
  mapIcon: <Map />,
  lineChartIcon: <LuLineChart />,
  policyIcon: <LuScrollText />,
  settingsIcon: <LuCog />,
  supportIcon: <LuLifeBuoy />,
  logoutIcon: <LuLogOut />,
  editIcon: <LuPencil />,
  archiveIcon: <LuArchive />,
  closeIcon: <LuX />,
  folderOpenIcon: <LuFolderOpen />,
  calendarIcon: <LuCalendarDays />,
  fileCheck2Icon: <LuFileCheck2 />,
  previewIcon: <LuEye />,
  infoIcon: <LuInfo />,
  checkCircleIcon: <LuCheckCircle2 />,
};

export default customIcons;
