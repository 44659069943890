/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { useEffect, useRef, useState } from 'react';
import customIcons from '../utils/icons';
import { ModalProps } from '../types/global';

export default function Modal({
  children,
  isOpen,
  hasCloseBtn,
  onClose,
}: ModalProps) {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <dialog id="modal" ref={modalRef} onKeyDown={handleKeyDown} className="flex flex-col justify-between items-center absolute inset-0 m-auto p-4 border-0 rounded-md shadow-lg bg-white h-72 w-1/2 text-center backdrop:bg-pipgrey-light">
      {children}
      {hasCloseBtn && (<button type="button" id="cancelModalButton" className="absolute top-0 right-0 p-1 m-4 shadow-inner bg-white rounded-md text-pip-background border" onClick={handleCloseModal}>{customIcons.closeIcon}</button>)}
    </dialog>
  );
}
