export default function SplitLayout(props: { children: any; leftWeight: any; rightWeight: any; }) {
  const { children, leftWeight, rightWeight } = props;
  const [left, right] = children;

  return (
    <div className="flex flex-col w-full h-full p-2 items-center md:flex-row md:items-start overflow-auto">
      <div className={`flex w-full mx-2 p-2 ${leftWeight ? `${leftWeight} font-thin` : 'flex-col h-full border  border-pipgrey-light rounded-md shadow'}`}>{left}</div>
      <div className={`flex w-full mx-2 p-2 ${rightWeight ? `${rightWeight} font-thin` : 'flex-col h-full border  border-pipgrey-light rounded-md shadow'}`}>{right}</div>
    </div>
  );
}
