// import { useLoaderData } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';
import Table from '../components/table';
import customIcons from '../utils/icons';
import { getCampaign } from '../utils/campaign';
import { CampaignData } from '../types/global';

export const loader = ({ params }:{ params: any }) => {
  // use the params to fetch campaign data based on the id.
  const { campaignId } = params;
  console.log(params);
  const campaignData = getCampaign(campaignId);
  return campaignData;
};

export default function CampaignConfirmation() {
  // have to coerce this to the correct type
  const data = useLoaderData() as CampaignData;
  const { status, createdAt } = data;
  console.log(data);
  const { campaignName, campaignType, snapShot } = data.campaignInformation;

  const tableObject = [{
    'Campaign Name': campaignName,
    'Last Updated': new Date(createdAt).toLocaleDateString(),
    'Lift Snapshot': snapShot,
    'Campaign Type': campaignType,
    Status: status,
  }];
  const ActionColumn = {
    Action: [{ title: 'edit', icon: customIcons.archiveIcon }],
  };
  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Campaign Status</h1>
      <Table tableObject={tableObject} ActionColumn={ActionColumn} title="Campaigns" count="campaign" />
    </div>
  );
}
