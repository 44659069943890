import customIcons from '../utils/icons';

export default function InfoBubble() {
  return (
    <div className="flex flex-row bg-pipstatus-info bg-opacity-25 rounded border border-pipstatus-info items-center p-1 mt-2 mb-2">
      <span className="m-2 text-pipstatus-info">{customIcons.infoIcon}</span>
      <p className="m-2 font-thin text-pipgrey-dark">
        {' '}
        Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.
      </p>
    </div>
  );
}
