import localforage from 'localforage';
import { matchSorter } from 'match-sorter';
import sortBy from 'sort-by';
import { CampaignData } from '../types/global';

export const set = (campaigns: any) => localforage.setItem('campaigns', campaigns);

// using this in the createCampaign to check against local ids
export const getCampaigns = async (query?: any): Promise<CampaignData[]> => {
  let campaigns = await localforage.getItem<CampaignData[]>('campaigns');
  if (!campaigns) campaigns = [];
  if (query) {
    campaigns = matchSorter(campaigns, query, { keys: ['id', 'snapShot', 'campaignType', 'campaignName', 'status'] });
  }
  return campaigns.sort(sortBy('id', 'createdAt'));
};

export const createCampaign = async (campaignInformation: any) => {
  const id = Math.random().toString(36).substring(2, 9);
  const campaign = {
    id, createdAt: Date.now(), campaignInformation, status: 'draft',
  };
  const campaigns = await getCampaigns();
  campaigns.unshift(campaign);
  await set(campaigns);
  return campaign;
};

export const getCampaign = async (id: string) => {
  const campaigns = await localforage.getItem<CampaignData[]>('campaigns');
  if (campaigns === null) return null;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const campaign = campaigns.find((campaign) => campaign.id === id);
  return campaign ?? null;
};
