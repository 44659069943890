import { ReactNode, useMemo } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../utils/auth';

type DecodedType = Record<string, any>;

export default function AuthProvider({ children }: { children: ReactNode }) {
  // caches the value so it doesn't update on every rerender
  const values = useMemo(() => ({ user: null }), []);

  const cookie = Cookies.get('token');
  if (cookie) {
    try {
      const decoded:DecodedType = jwtDecode(cookie);
      values.user = decoded?.email;
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
  if (!values.user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
  );
}
