import {
  faWindowMinimize, faAngleLeft, faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface CollapseButtonProps {
  toggle: () => void; // Defining the type of 'toggle' as a function returning void
  isExpanded: boolean;
}

// eslint-disable-next-line react/prop-types
export default function CollapseButton({ toggle, isExpanded }: CollapseButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  const setIcon = () => {
    if (isHovered && isExpanded) {
      return <FontAwesomeIcon icon={faAngleLeft} color="grey" />;
    }
    if (isHovered && !isExpanded) {
      return <FontAwesomeIcon icon={faAngleRight} color="grey" />;
    }
    return <FontAwesomeIcon icon={faWindowMinimize} rotation={90} color="grey" />;
  };

  return (
    <button aria-label="expand-or-collapse-menu-button" className="bg-transparent block" onClick={toggle} type="button" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <span className="fa-layers fa-fw">
        {setIcon() }
      </span>
    </button>

  );
}
