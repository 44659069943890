/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { AdvancedInputProps } from '../types/global';

function AdvancedInput({
  type, label, id, ...otherProps
}: AdvancedInputProps) {
  switch (type) {
    case 'slider':
      return (
        <div>
          <label htmlFor={id}>{label}</label>
          <input type="range" id={id} {...otherProps} />
        </div>
      );
    case 'dropdown':
      return (
        <label htmlFor={id} className="flex flex-col p-2 justify-between font-thin w-full lg:w-1/2">
          <span className="text-sm">{label}</span>
          <select id={id} {...otherProps} name={id} className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey">
            <option value="" disabled>Please select option</option>
            {otherProps.options.map((option:string, index:number) => (
              <option key={`${option}-${index}`} value={option}>{option}</option>
            ))}
          </select>
        </label>
      );

    case 'radio':
      return (
        <fieldset>
          <legend>{label}</legend>
          {otherProps.options.map((option:string, index:number) => (
            <label htmlFor={label} key={`${option}-${index}`}>
              <div>
                <input type="radio" id={`${option}-${index}`} name={id} value={option} />
                {option}
              </div>
            </label>
          ))}
        </fieldset>
      );
    case 'checkbox':
      return (
        <fieldset>
          <legend className="">{label}</legend>
          {otherProps.options.map((option:string, index:number) => (
            <label htmlFor={option} key={`${option}-${index}`}>
              <div>
                <input type="checkbox" id={`${option}-${index}`} name={id} value={option} />
                {option}
              </div>
            </label>
          ))}
        </fieldset>
      );
    default:
      return (
        <div className="flex flex-col p-2 justify-between font-thin w-full lg:w-1/2">
          <label htmlFor={id} className="flex-none mr-2 p-1 text-sm">
            {label}
          </label>
          <input type="text" id={id} className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey " {...otherProps} />
        </div>
      );
  }
}

export default AdvancedInput;
