import { IconButton, StepState, StepsProps } from '../../types/global';
import InformationText from '../InformationText';

export default function Steps({ steps, titleObject, input }: { steps: StepsProps[], titleObject: IconButton, input?: StepState }) {
  const stepDone = (campaignState: StepState, step: string) => {
    if (step === 'Choose your campaign' && campaignState.campaignName !== '') {
      return 'text-pipgreen';
    } if (step === 'Choose Snapshot' && campaignState.snapShot !== '') {
      return 'text-pipgreen';
    } if (step === 'Create Campaign' && campaignState.campaignType !== '') {
      return 'text-pipgreen';
    }
    return 'text-pipgrey';
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fadeStep = (campaignState: StepState, step: string) => {
    if (step === 'Choose your campaign' && campaignState.campaignName !== '') {
      return 'opacity-60';
    } if (step === 'Choose Snapshot' && campaignState.snapShot !== '') {
      return 'opacity-60';
    } if (step === 'Create Campaign' && campaignState.campaignType !== '') {
      return 'opacity-60';
    }
    return '';
  };

  return (
    <div className="overflow-auto">
      <div className="flex flex-row items-center ">
        <span className="m-2 p-2 text-2xl text-pipgreen">{titleObject.icon}</span>
        <h2 className="text-2xl font-medium">{titleObject.title}</h2>
      </div>
      <p className="m-2 p-2 font-thin">
        Lorem ipsum dolor sit amet consectetur. Sed ullamcorper sit varius facilisis nulla morbi.
        {' '}
      </p>
      {input && steps.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item.step}-${index}`} className={`flex flex-row items-center mx-2 px-2 ${fadeStep(input, item.step)}`}>
          <div className={`w-fit h-fit border-2 rounded p-4 text-xl ${stepDone(input, item.step)}`}>{item.icon}</div>
          <InformationText title={item.step} subtitle={item.info} />
        </div>
      ))}
    </div>
  );
}

Steps.defaultProps = {
  campaignName: '',
  campaignType: '',
  snapShot: '',
} as StepState | undefined;
