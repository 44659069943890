import { InformationProps } from '../types/global';

export default function UserText({
  state, title, subtitle,
}: InformationProps) {
  return (
    <div
      id="user-information"
      className={`${state ? 'flex-row justify-between font-thin m-2' : 'flex m-2'}`}
    >
      {state && (
      <div className="flex flex-grow">
        <div className={`flex flex-col p-2${state ? '' : 'text-xs'} `}>
          <span className="font-medium">{title}</span>
          <span className="text-pipgrey">{subtitle}</span>
        </div>
      </div>
      )}
    </div>
  );
}
