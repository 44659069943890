import { useLoaderData, useNavigate } from 'react-router-dom';
import Table from '../components/table';
import customIcons from '../utils/icons';
import { getCampaigns } from '../utils/campaign';
import { TableProps } from '../types/global';

export function loader({ params }:{ params: any }) {
  // use the params to fetch campaign data based on the id.
  const { status } = params;
  try {
    const campaignData = getCampaigns(status);
    return campaignData;
  } catch (error) {
    console.log('error:', error);
    return undefined;
  }
}

export default function Campaigns() {
  const data = useLoaderData();
  const newData: TableProps[] = [];
  const navigate = useNavigate();
  if (Array.isArray(data)) {
    data.forEach((campaign:any) => {
      const { createdAt, status } = campaign;
      const { campaignName, snapShot, campaignType } = campaign.campaignInformation;
      newData.push({
        'Campaign Name': campaignName,
        'Last Updated': new Date(createdAt).toLocaleDateString(),
        'Lift Snapshot': snapShot,
        'Campaign Type': campaignType,
        Status: status,
      });
    });
  }
  const ActionColumn = {
    Action: [{ title: 'edit', icon: customIcons.archiveIcon }],
  };
  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between border-b-2 items-center">
        <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaigns</h1>
        <button type="button" className=" bg-pipgreen text-white rounded-md px-2 mx-2 h-1/3" onClick={() => navigate('/campaigns/new')}>create new campaign</button>
      </div>
      <Table tableObject={newData} ActionColumn={ActionColumn} title="Campaigns" count="campaign" />
    </div>
  );
}
