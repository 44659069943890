import { Outlet } from 'react-router-dom';
import SideMenu from './sideMenu';
import AuthProvider from './context/authProvider';

export default function AppLayout() {
  return (
    <AuthProvider>
      <div className="relative flex h-screen w-full ">
        <div className="bg-transparent flex ">
          <SideMenu />
        </div>
        <main className="flex flex-grow w-full overflow-auto bg-pipgrey-background">
          <Outlet />
        </main>
      </div>
    </AuthProvider>
  );
}
