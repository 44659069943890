import { LogoProps } from '../types/global';

export default function Logo({ src, alt, className }: LogoProps) {
  if (src === (undefined || null || '')) {
    return '/pip_green_logo.svg';
  }
  // OPTION: added classname as a prop so that the logo can have fixed width/height where appropriate and to prevent
  return (
    <div className="flex justify-left align-middle overflow-hidden h-9 w-auto mt-2 mx-2 mb-2">
      <a href="/">
        <img src={src} alt={alt} className={className ? `${className} ` : 'max-h-full max-w-full object-contain'} />
      </a>
    </div>
  );
}
