export default function List({ campaign }: { campaign: any }) {
  return (
    <>
      <h2 className="text-2xl font-medium p-2">Campaign Details</h2>
      <ul className="flex flex-wrap justify-between w-full">
        {Object.keys(campaign).map((key) => (
          <li key={`list-item-${key}`}>
            <div className="flex flex-col p-2 justify-between font-thin w-full">
              <label htmlFor={key} className="flex-none mr-2 p-1 text-sm">
                {key}
              </label>
              <input type="text" id={key} className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey " value={campaign[key]} />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
