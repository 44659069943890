import { InformationProps } from '../types/global';

export default function InformationText({ title, subtitle }: InformationProps) {
  return (
    <div
      id="user-information"
      className="flex-row justify-between font-thin flex m-2"
    >

      <div className="flex flex-grow">
        <div className="flex flex-col p-2 'text-xs">
          <span className="font-medium">{title}</span>
          <span className="text-pipgrey">{subtitle}</span>
        </div>
      </div>

    </div>
  );
}
